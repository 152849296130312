import React from 'react';

import { LoadableAfterPaint } from '@confluence/loadable';

import { PresenceAvatarGroupPlaceholder } from '../src/presence-avatar-group/PresenceAvatarGroupPlaceholder';

export const ContentHeaderPresenceLoader = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ContentHeaderPresence" */ '../src/presence/ContentHeaderPresence'
			)
		).ContentHeaderPresence,
	loading: () => {
		return <PresenceAvatarGroupPlaceholder />;
	},
});
