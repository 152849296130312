import { LoadablePaint, LoadableLazy } from '@confluence/loadable';

import { ForgeContentToolsPlaceholder } from './ForgeContentToolsPlaceholder';

export const ContentTools = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ContentTools" */ './ContentTools')).ContentTools,
});

export const ForgeContentTools = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ForgeContentTools" */ './ForgeContentTools'))
			.ForgeContentTools,

	loading: ForgeContentToolsPlaceholder,
});
