/* eslint-disable react/no-danger -- when updating this component, consider replacing dangerous jsx properties */
import React, { useRef, useLayoutEffect } from 'react';

import { evalLegacyConnectInlineScripts } from '@confluence/connect-utils';

type WebPanelProps = {
	html: string;
};

export function WebPanel({ html }: WebPanelProps) {
	const containerRef = useRef(null);

	useLayoutEffect(() => {
		void evalLegacyConnectInlineScripts(containerRef.current);
	}, [html]);

	return <div ref={containerRef} dangerouslySetInnerHTML={{ __html: html }} />;
}
