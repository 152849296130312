import gql from 'graphql-tag';

export const DefaultAndSpaceClassificationLevelsQuery = gql`
	query DefaultAndSpaceClassificationLevelsQuery($spaceKey: String!) {
		space(key: $spaceKey) {
			id
			name
			defaultClassificationLevelId
			metadata {
				totalCurrentPages
			}
		}
		classificationLevels {
			id
			order
			status
			color
			description
			guideline
			name
		}
	}
`;
