import gql from 'graphql-tag';

export const BulkDeleteContentDataClassificationLevelMutation = gql`
	mutation BulkDeleteContentDataClassificationLevel(
		$id: Long!
		$contentStatuses: [ContentDataClassificationMutationContentStatus]!
	) {
		bulkDeleteContentDataClassificationLevel(
			input: { id: $id, contentStatuses: $contentStatuses }
		) {
			success
			errors {
				message
			}
		}
	}
`;
