import { ContentStatus } from '../constants/ContentStatus';
import type { ClassificationQueryContext } from '../hooks/useContentClassification';

/**
 * Note: when ContentStatus is 'current', we always pass both 'current' and 'archived' to the read query
 *  as the singleContent response will return one or the other, depending on the archive status.
 */
export const getReadAndWriteContextFromQueryContext = (
	context: ClassificationQueryContext,
): {
	readContext: ContentStatus[];
	writeContext: ContentStatus[];
} => {
	let readContext;
	let writeContext;
	switch (context) {
		case 'draft-only':
			readContext = [ContentStatus.DRAFT];
			writeContext = [ContentStatus.DRAFT];
			break;
		case 'current-only':
			readContext = [ContentStatus.CURRENT, ContentStatus.ARCHIVED];
			writeContext = [ContentStatus.CURRENT];
			break;
		case 'current-and-draft':
			readContext = [ContentStatus.CURRENT, ContentStatus.ARCHIVED];
			writeContext = [ContentStatus.CURRENT, ContentStatus.DRAFT];
			break;
		default:
			readContext = [ContentStatus.CURRENT, ContentStatus.ARCHIVED];
			writeContext = [ContentStatus.CURRENT];
	}
	return { readContext, writeContext };
};
