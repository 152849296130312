import type { Color } from '@atlassian/data-classification-level';

import { Status } from './Status';

export const NO_CLASSIFICATION = 'NO_CLASSIFICATION';

export const NO_CLASSIFICATION_OPTION_ID = 'NO_CLASSIFICATION_OPTION';
export const NO_CLASSIFICATION_OPTION = {
	id: NO_CLASSIFICATION_OPTION_ID,
	name: 'No default',
	order: 0,
	definition: 'Pages in this space aren’t classified by default.',
	color: 'NONE' as Color,
	status: Status.PUBLISHED,
};
