import { ContentStatus } from '../constants/ContentStatus';

const classificationContentStatusEnumToSingleContentSearchArrayMap: {
	[k: string]: string;
} = {
	[ContentStatus.CURRENT]: 'current',
	[ContentStatus.DRAFT]: 'draft',
	[ContentStatus.ARCHIVED]: 'archived',
};

const getSingleContentStatusStringFromClassificationStatus = (status: ContentStatus): string => {
	return classificationContentStatusEnumToSingleContentSearchArrayMap[status];
};

export const getQuerySingleContentStatusArrayVariable = (statuses: ContentStatus[]): string[] => {
	return statuses
		.map((status) => getSingleContentStatusStringFromClassificationStatus(status))
		.filter(Boolean);
};
