import type { FC } from 'react';
import React, { memo, useEffect } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import type { PageLoadMetric } from '@atlassian/browser-metrics';

import { ProgressBarContainer } from '@confluence/global-progress-bar-container';
import { getUniquePageLoadId } from '@confluence/unique-page-load-id';
import { resumeLowPriorityEvents } from '@confluence/analytics-event-delay';
import { Attribution, withTransparentErrorBoundary } from '@confluence/error-boundary';

import { browserMetricsState, markBrowserMetricEnd } from './utils';

type PageLoadEndImplProps = Props & { onComplete: () => void };

const PageLoadEndImpl: FC<PageLoadEndImplProps> = memo(
	({ metric, customData = {}, stopTime, onComplete }) => {
		useEffect(() => {
			const stopArguments = {
				stopTime: typeof stopTime === 'number' ? stopTime : undefined,
				customData: {
					...customData,
					previousRoute: browserMetricsState.previousRoute,
					pageLoadInfo: getUniquePageLoadId(),
				},
			};

			metric.stop(stopArguments);
			markBrowserMetricEnd(metric);

			void resumeLowPriorityEvents();

			// trigger onComplete regardless of FF - PageLoadEnd can stop the progress bar temporarily,
			// as ProgressBarContainer returns early if the progress bar has already been stopped.
			onComplete();

			// Not adding exhaustive dependencies because we want this to fire only once,
			// despite any changes to `customData` or `stopTime`
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [onComplete, metric]);

		return null;
	},
);

export type Props = {
	metric: PageLoadMetric;
	customData?: any;
	stopTime?: number | null;
};

const PageLoadEndComponent: FC<Props> = memo(({ metric, customData, stopTime }) => {
	return (
		<Subscribe to={[ProgressBarContainer]}>
			{(progressBar: ProgressBarContainer) => (
				<PageLoadEndImpl
					metric={metric}
					customData={customData}
					stopTime={stopTime}
					onComplete={progressBar.finish}
				/>
			)}
		</Subscribe>
	);
});

export const PageLoadEnd = withTransparentErrorBoundary({
	attribution: Attribution.BACKBONE,
})(PageLoadEndComponent);
