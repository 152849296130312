import gql from 'graphql-tag';

export const byLineUserFragment = gql`
	fragment byLineUserFragment on Person {
		type
		displayName
		... on KnownUser {
			accountId
		}
		... on User {
			accountId
		}
		profilePicture {
			path
		}
	}
`;
