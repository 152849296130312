import { LoadableAfterPaint } from '@confluence/loadable';

export { useLoomInsertUrlState } from './useLoomInsertUrlState';

export const PageHeaderLoomEntryPoint = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PageHeaderLoomEntryPoint" */ './PageHeaderLoomEntryPoint'
			)
		).PageHeaderLoomEntryPoint,
});
