export const VIEW_EDIT_BUTTON_CLICKED_ACTION = 'viewEditButtonClicked';
export const EDIT_ROUTE_CHANGE_ACTION = 'editButtonClickedToEditRouteChange';
export const EDIT_ROUTE_CHANGE_TO_FMP_ACTION = 'editRouteChangeToPageLoad';
export const TINY_SYNCHRONY_PLUGINS_LOAD_ACTION = 'tinySynchronyPluginsLoad';
export const TINY_SYNCHRONY_CONTENT_FETCH_ACTION = 'tinySynchronyContentFetch';

export const EDIT_INTENT_EDIT_TTI_ACTION = 'editIntentToEditPageTTI';
export const EDIT_INTENT_EDIT_FMP_ACTION = 'editIntentToEditPageFMP';
export const EMBEDDED_EDIT_TTI_ACTION = 'editEmbeddedPageTTI';
export const EMBEDDED_EDIT_FMP_ACTION = 'editEmbeddedPageFMP';
export const EDIT_ROUTE_CHANGE_TO_TTI_ACTION = 'routeChangeToEditorTTI';

export const TINY_SYNCHRONY_CONTENT_METADATA_PRELOAD = 'tinySynchronyContentMetadaPreload';
