import type { createPageLoadMetric } from '../metricConfigs';

import { isInitial } from './browserMetricsState';

export const markBrowserMetricEnd = (metric?: ReturnType<typeof createPageLoadMetric>) => {
	let params: [
		startOrMeasureOptions?: string | PerformanceMeasureOptions | undefined,
		endMark?: string | undefined,
	];
	try {
		if (isInitial()) {
			if (metric) {
				const data = metric.getData?.();
				const start = data?.start || 0;
				const stop = data?.stop || 0;
				params = [{ duration: stop - start, end: 'browser-metric.end' }];
			} else {
				params = [undefined, 'browser-metric.end'];
			}
		} else {
			params = ['browser-metric.start', 'browser-metric.end'];
		}

		performance.mark('browser-metric.end');
		performance.measure('browser-metric:tti', ...params);
	} catch (e) {
		// Suppress error when performance API failed.
		// Note: in MicrosoftEdge version 18 and prior, performance.measure(name, startMark, endMark) API will throw SyntaxError if startMark is undefined.
		// That's why wrap with try catch statement.
	}
};
