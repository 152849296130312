import type { MutationFunction } from 'react-apollo';
import { useMutation, useQuery } from 'react-apollo';
import type { Dispatch } from 'react';
import { useState, useMemo, useEffect, useCallback } from 'react';
import type { ApolloError } from 'apollo-client';

import { markErrorAsHandled } from '@confluence/graphql';
import { isStatusCodeError } from '@confluence/error-types';

import { ContentDataClassificationQuery } from '../queries/ContentDataClassificationQuery.graphql';
import { ClassificationLevelSource } from '../queries/__types__/ContentDataClassificationQuery';
import type { ClassificationLevel } from '../ClassificationRadioOption';
import { DefaultAndSpaceClassificationLevelsQuery } from '../queries/DefaultAndSpaceClassificationQuery.graphql';
import { NO_CLASSIFICATION_OPTION_ID } from '../constants/NoClassification';
import { BulkUpdateContentDataClassificationLevelMutation } from '../queries/BulkUpdateContentDataClassificationLevelMutation.graphql';
import { BulkDeleteContentDataClassificationLevelMutation } from '../queries/BulkDeleteContentDataClassificationLevelMutation.graphql';
import { FlagStatus } from '../constants/FlagStatus';
import type {
	BulkUpdateContentDataClassificationLevel as BulkUpdateContentDataClassificationLevelType,
	BulkUpdateContentDataClassificationLevelVariables,
} from '../queries/__types__/BulkUpdateContentDataClassificationLevelMutation';
import type {
	BulkDeleteContentDataClassificationLevel as BulkDeleteContentDataClassificationLevelType,
	BulkDeleteContentDataClassificationLevelVariables,
} from '../queries/__types__/BulkDeleteContentDataClassificationLevelMutation';
import { Status } from '../constants/Status';
import type { ErrorState } from '../constants/ErrorState';
import { getQuerySingleContentStatusArrayVariable } from '../utils/getQuerySingleContentStatusArrayVariable';
import { getReadAndWriteContextFromQueryContext } from '../utils/getReadAndWriteContextFromQueryContext';

import { useClassificationErrorState } from './useClassificationErrorState';
import { useIsDataClassificationEnabledForOrg } from './useIsDataClassificationEnabledForOrg';

/**
 * Return type for useContentClassification hook. Includes:
 * 1) Content's classification level by contentId & by spaceId,
 * 2) Mutation functions for updating & deleting the content's classification,
 * 3) Flag status for rendering error flag upon updating classification.
 */
export type ContentClassificationMetadata = {
	classification: ClassificationLevel | null;
	spaceClassification: ClassificationLevel | null;
	spaceDefaultIsOverridden: boolean;
	errorState: ErrorState;
	contentLevelError?: ApolloError;
	spaceLevelError?: ApolloError;
	shouldDisplayClassification: boolean;
	updateContentDataClassificationLevel: MutationFunction<
		BulkUpdateContentDataClassificationLevelType,
		BulkUpdateContentDataClassificationLevelVariables
	>;
	deleteContentDataClassificationLevel: MutationFunction<
		BulkDeleteContentDataClassificationLevelType,
		BulkDeleteContentDataClassificationLevelVariables
	>;
	setSubmittedClassification: Dispatch<ClassificationLevel | null>;
	flagStatus: FlagStatus;
	setFlagStatus: Dispatch<FlagStatus>;
	hasNoClassification?: boolean;
	contentLevelLoading?: boolean;
	spaceLevelLoading?: boolean;
	getClassificationLevelFromId: (
		classificationLevelId?: string | null,
	) => ClassificationLevel | undefined;
	contentStatusContext: ClassificationQueryContext;
};

// Note: this behavior is extracted into its own hook to allow for easier testing
interface UseSyncSubmittedClassificationOnFlagChangeI {
	flagStatus: FlagStatus;
	submittedClassification?: ClassificationLevel | null;
	setClassification: Dispatch<ClassificationLevel | null>;
	setFlagStatus: Dispatch<FlagStatus>;
}

export const useSyncSubmittedClassificationOnFlagChange = ({
	flagStatus,
	submittedClassification,
	setClassification,
	setFlagStatus,
}: UseSyncSubmittedClassificationOnFlagChangeI) => {
	// Updates the classification state with the value of submittedClassification
	// on flag success, ensuring consistent behavior across edit tag & edit modal
	useEffect(() => {
		if (flagStatus === FlagStatus.SUCCESS) {
			setClassification(submittedClassification ?? null);
			setFlagStatus(FlagStatus.NONE);
		}
	}, [flagStatus, submittedClassification, setClassification, setFlagStatus]);
};

/**
 * Determines the context of the ContentStatus for which the content classification is read and written.
 * 'draft-only' reads and writes to 'draft',
 * 'current-only' reads and writes to 'current',
 * 'current-and-draft' reads from 'current' and writes to 'current' & 'draft' (live-pages)
 */
export type ClassificationQueryContext = 'draft-only' | 'current-only' | 'current-and-draft';

type useContentClassificationType = {
	contentId?: string;
	spaceKeyOverride?: string | null;
	shouldSkipQueries?: boolean;
	contentStatusContext?: ClassificationQueryContext;
};

/**
 *
 * @param contentId
 * @param spaceKeyOverride
 * @param contentStatusContext
 * @param shouldSkipQueries
 * @returns
 * 1) Content's classification level by contentId & by spaceId,
 * 2) Mutation functions for updating & deleting the content's classification,
 * 3) Flag status for rendering error flag upon updating classification.
 */
export const useContentClassification = ({
	contentId,
	spaceKeyOverride,
	contentStatusContext = 'current-only',
	shouldSkipQueries = false,
}: useContentClassificationType): ContentClassificationMetadata => {
	const shouldPreloadContentClassificationData = window.__SSR_RENDERED__;

	const spaceKey = spaceKeyOverride;

	const [flagStatus, setFlagStatus] = useState(FlagStatus.NONE);
	const [classification, setClassification] = useState<ClassificationLevel | null>(null);
	const [currentContentId, setCurrentContentId] = useState<string | undefined>(undefined);
	const [submittedClassification, setSubmittedClassification] =
		useState<ClassificationLevel | null>();

	const { isEnabled: isDataClassificationEnabledForOrg } = useIsDataClassificationEnabledForOrg();

	const { readContext: contentStatusReadContext } =
		getReadAndWriteContextFromQueryContext(contentStatusContext);

	useSyncSubmittedClassificationOnFlagChange({
		flagStatus,
		submittedClassification,
		setClassification,
		setFlagStatus,
	});

	// The following two mutation functions are returned by this hook, behavior upon completion is handled within the hook
	const [updateContentDataClassificationLevel] = useMutation<
		BulkUpdateContentDataClassificationLevelType,
		BulkUpdateContentDataClassificationLevelVariables
	>(BulkUpdateContentDataClassificationLevelMutation, {
		onError: (updateClassificationError) => {
			setFlagStatus(FlagStatus.ERROR);
			markErrorAsHandled(updateClassificationError);
		},
		onCompleted: (data) => {
			if (data?.bulkUpdateContentDataClassificationLevel?.success) {
				setFlagStatus(FlagStatus.SUCCESS);
			}
		},
		refetchQueries: () => [
			{
				query: ContentDataClassificationQuery,
				variables: {
					contentId,
					lowerCaseContentStatusArray:
						getQuerySingleContentStatusArrayVariable(contentStatusReadContext),
				},
			},
		],
	});

	const [deleteContentDataClassificationLevel, { data: deleteData, loading: deleteLoading }] =
		useMutation<
			BulkDeleteContentDataClassificationLevelType,
			BulkDeleteContentDataClassificationLevelVariables
		>(BulkDeleteContentDataClassificationLevelMutation, {
			onError: (deleteClassificationError) => {
				setFlagStatus(FlagStatus.ERROR);
				markErrorAsHandled(deleteClassificationError);
			},
			onCompleted: (data) => {
				if (data?.bulkDeleteContentDataClassificationLevel?.success) {
					setFlagStatus(FlagStatus.SUCCESS);
				}
			},
			refetchQueries: () => [
				{
					query: ContentDataClassificationQuery,
					variables: {
						contentId,
						lowerCaseContentStatusArray:
							getQuerySingleContentStatusArrayVariable(contentStatusReadContext),
					},
				},
			],
		});

	const {
		data: objectLevelData,
		loading: objectLevelLoading,
		error: objectLevelError,
	} = useQuery(ContentDataClassificationQuery, {
		variables: {
			contentId,
			lowerCaseContentStatusArray:
				getQuerySingleContentStatusArrayVariable(contentStatusReadContext),
		},
		skip: shouldSkipQueries || !isDataClassificationEnabledForOrg || !contentId,
		errorPolicy: 'all',
		fetchPolicy: shouldPreloadContentClassificationData ? 'cache-first' : 'network-only',
	});

	const classificationLevelDetails = objectLevelData?.singleContent?.classificationLevelDetails;
	const contentClassification = classificationLevelDetails?.classificationLevel;

	const contentLevelLoading = objectLevelLoading;
	const contentLevelError = objectLevelError;

	const spaceDefaultIsOverridden =
		classificationLevelDetails?.source === ClassificationLevelSource.CONTENT;

	// Query for fetching a page's classification by its spaceId
	const {
		data: spaceLevelData,
		loading: spaceLevelLoading,
		error: spaceLevelError,
	} = useQuery(DefaultAndSpaceClassificationLevelsQuery, {
		variables: { spaceKey },
		skip: shouldSkipQueries || !isDataClassificationEnabledForOrg || !spaceKey,
		fetchPolicy: shouldPreloadContentClassificationData ? 'cache-first' : 'network-only',
		onError: (error) => {
			if (isStatusCodeError(error, '404')) {
				markErrorAsHandled(error);
			}
		},
	});

	const errorState = useClassificationErrorState(
		classification,
		spaceLevelError,
		contentLevelError,
	);

	const spaceClassificationId = spaceLevelData?.space?.['defaultClassificationLevelId'];

	const classificationLevels = spaceLevelData?.['classificationLevels'];
	const getClassificationLevelFromId = useCallback(
		(classificationLevelId?: string | null) => {
			return classificationLevels?.find(
				(level: ClassificationLevel) => level.id === classificationLevelId,
			);
		},
		[classificationLevels],
	);
	const spaceClassification = getClassificationLevelFromId(spaceClassificationId);
	const isSpaceClassificationArchived = spaceClassification?.status === Status.ARCHIVED;

	// Determine whether to set classification either content level classification, or space default classification, or null through a series of booleans.

	// Cases where we should not display content classification:
	// 1. If "No classification" option is selected as content classification
	// 2. If content classification was archived
	// 3. If classification value from state hook is null (i.e. classification was deleted) and delete mutation is still loading
	// 4. If delete was successful and classification value from state hook is null
	// 5. If there was an error fetching content level classification
	const shouldNotDisplayContentClassification =
		contentClassification?.id === NO_CLASSIFICATION_OPTION_ID ||
		contentClassification?.status === Status.ARCHIVED ||
		(classification === null && deleteLoading) ||
		(deleteData?.bulkDeleteContentDataClassificationLevel?.success && classification === null) ||
		Boolean(contentLevelError);

	// Cases where we should display no classification instead of space default classification
	// 1. If space classification is undefined
	// 2. If space classification was archived,.
	const spaceClassificationOrNull =
		!spaceClassification || spaceClassification?.status === Status.ARCHIVED
			? null
			: spaceClassification;

	// This ternary determines whether to display content classification value from query or from state hook.
	// Checks whether content ID has changed since the last render, and if it has then we display the classification value from query.
	// (Resolves bug where classification wasn't properly updating when navigating between pages via page tree)
	const contentClassificationFromQueryOrState =
		currentContentId === contentId && !!classification ? classification : contentClassification;

	// Ultimately, we'll be displaying one of the three values as a content's current classification (in order of priority):
	// 1. Content level classification
	// 2. Space default classification
	// 3. No classification (will render Add Classification button instead)
	const updatedClassification = shouldNotDisplayContentClassification
		? spaceClassificationOrNull
		: contentClassificationFromQueryOrState;

	useEffect(() => {
		if (updatedClassification !== classification) {
			setClassification(updatedClassification);
			setCurrentContentId(contentId);
		}
	}, [updatedClassification, classification, contentId]);

	const isClassificationPublished =
		classification?.status === Status.PUBLISHED ||
		updatedClassification?.status === Status.PUBLISHED;

	const shouldDisplayClassification =
		isDataClassificationEnabledForOrg &&
		(!!classification || !!updatedClassification) &&
		!contentLevelLoading &&
		!spaceLevelLoading &&
		isClassificationPublished;

	const hasNoClassification =
		!contentLevelLoading && !spaceLevelLoading && !classification && !updatedClassification;

	//useEffect is not run during SSR, so we can't use classification from the state.
	//we cant use setClassification either as it causes side effects which causes unnecessary re-renders.
	//Instead, we can use updatedClassification for initial load for SSR.
	const isSSRedWithClassification = process.env.REACT_SSR && updatedClassification;

	return useMemo<ContentClassificationMetadata>(
		() => ({
			classification: isSSRedWithClassification ? updatedClassification : classification,
			spaceDefaultIsOverridden,
			contentLevelLoading,
			contentLevelError,
			spaceLevelLoading,
			spaceLevelError,
			updateContentDataClassificationLevel,
			deleteContentDataClassificationLevel,
			setSubmittedClassification,
			flagStatus,
			setFlagStatus,
			errorState,
			shouldDisplayClassification,
			hasNoClassification,
			spaceClassification: isSpaceClassificationArchived ? null : spaceClassification,
			getClassificationLevelFromId,
			contentStatusContext,
		}),
		[
			classification,
			updatedClassification,
			spaceDefaultIsOverridden,
			contentLevelLoading,
			contentLevelError,
			spaceLevelLoading,
			spaceLevelError,
			updateContentDataClassificationLevel,
			deleteContentDataClassificationLevel,
			setSubmittedClassification,
			flagStatus,
			setFlagStatus,
			errorState,
			shouldDisplayClassification,
			hasNoClassification,
			spaceClassification,
			isSpaceClassificationArchived,
			isSSRedWithClassification,
			getClassificationLevelFromId,
			contentStatusContext,
		],
	);
};
