import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import AkButton from '@atlaskit/button/custom-theme-button';
import AkMoreIcon from '@atlaskit/icon/glyph/editor/more';

const i18n = defineMessages({
	more: {
		id: 'content-tools.placeholder.ellipsis.more',
		description: 'The label of the More icon',
		defaultMessage: 'More',
	},
});

export const ForgeContentToolsPlaceholder = () => {
	const intl = useIntl();
	const label = intl.formatMessage(i18n.more);
	const moreIcon = <AkMoreIcon label={label} />;
	return <AkButton isDisabled iconBefore={moreIcon} appearance="subtle" />;
};
