/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ClassificationLevelSource {
  CONTENT = "CONTENT",
  ORGANIZATION = "ORGANIZATION",
  SPACE = "SPACE",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ContentDataClassificationQuery
// ====================================================

export interface ContentDataClassificationQuery_singleContent_classificationLevelDetails_classificationLevel {
  id: string;
  guideline: string | null;
  description: string | null;
  color: string | null;
  name: string;
  status: string;
}

export interface ContentDataClassificationQuery_singleContent_classificationLevelDetails {
  classificationLevel: ContentDataClassificationQuery_singleContent_classificationLevelDetails_classificationLevel | null;
  source: ClassificationLevelSource | null;
}

export interface ContentDataClassificationQuery_singleContent {
  id: string | null;
  classificationLevelDetails: ContentDataClassificationQuery_singleContent_classificationLevelDetails | null;
}

export interface ContentDataClassificationQuery {
  singleContent: ContentDataClassificationQuery_singleContent | null;
}

export interface ContentDataClassificationQueryVariables {
  contentId: string;
  lowerCaseContentStatusArray?: (string | null)[] | null;
}
