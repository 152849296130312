import { useQuery } from 'react-apollo';

import { DataClassificationEnabledQuery } from '../queries/DataClassificationEnabled.graphql';

// hook to determine if data classification is enabled for org
export const useIsDataClassificationEnabledForOrg = () => {
	const { data, error, loading } = useQuery(DataClassificationEnabledQuery, {
		context: { single: true },
	});

	const isEnabled = Boolean(data?.isDataClassificationEnabled);

	return { isEnabled, error, loading };
};
