import gql from 'graphql-tag';

export const ContentDataClassificationQuery = gql`
	query ContentDataClassificationQuery($contentId: ID!, $lowerCaseContentStatusArray: [String]) {
		singleContent(id: $contentId, status: $lowerCaseContentStatusArray) {
			id
			classificationLevelDetails {
				classificationLevel {
					id
					guideline
					description
					color
					name
					status
				}
				source
			}
		}
	}
`;
