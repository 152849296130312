import gql from 'graphql-tag';

export const BulkUpdateContentDataClassificationLevelMutation = gql`
	mutation BulkUpdateContentDataClassificationLevel(
		$id: Long!
		$contentStatuses: [ContentDataClassificationMutationContentStatus]!
		$classificationLevelId: ID!
	) {
		bulkUpdateContentDataClassificationLevel(
			input: {
				id: $id
				contentStatuses: $contentStatuses
				classificationLevelId: $classificationLevelId
			}
		) {
			success
			errors {
				message
			}
		}
	}
`;
