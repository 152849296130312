import type { ApolloError } from 'apollo-client';
import type { GraphQLError } from 'graphql';

//This is designed to detect several errors that CFE onCall can do nothing about to help reduce noise.
// to use simply use a block like so:
// if (error) { //GQL error from apollo hook or query component
//     if(isExpectedApolloError(error)){ //this function
//       markErrorAsHandled(error);
//       experienceTracker.abort({
//         name: YOUR_EXPERIENCE,
//         reason: "Expected GQL error",
//       });
//     } else {
//       experienceTracker.stopOnError({
//         name: YOUR_EXPERIENCE,
//         error,
//       });
//   }
// This file was designed to handle errors that include these strings in the message field:
// com.atlassian.confluence.api.service.exceptions.NotFoundException: Space not found ,
// com.atlassian.confluence.api.service.exceptions.NotFoundException: Cannot find space,
// Not permitted to view space with id ___,

export const isExpectedApolloError = (error: ApolloError | undefined): boolean => {
	if (error) {
		return error.graphQLErrors?.every(isExpectedGraphqlError);
	}
	return false;
};

const isExpectedGraphqlError = (error: GraphQLError) => {
	return (
		isConfigMissingSpaceError(error) ||
		isConfigSpacePermissionError(error) ||
		isConfigUserPermissionError(error) ||
		isConfigDeletedSpaceError(error)
	);
};
//invalid space key
export const isConfigMissingSpaceError = (error: GraphQLError) => {
	return error.message?.includes('Space not found');
};

// Deleted space id error when the user is following a deleted space
export const isConfigDeletedSpaceError = (error: GraphQLError) => {
	return error.message?.includes('Cannot find space with id:');
};

// Permission check error when the user is following a restricted space
export const isConfigSpacePermissionError = (error: GraphQLError) => {
	return error.message?.includes('Not permitted to view space with id:');
};

export const isConfigUserPermissionError = (error: GraphQLError) => {
	return error.message?.includes('User not permitted to access Confluence');
};
