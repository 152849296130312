import { createStore, createHook } from 'react-sweet-state';

export type EditSourceState = {
	editSource: string;
};

export const initialEditSourceState: EditSourceState = {
	editSource: '',
};
const Store = createStore({
	initialState: {
		...initialEditSourceState,
	},
	actions: {
		setEditSource:
			(editSource: string) =>
			({ setState }) => {
				setState({
					editSource,
				});
			},
	},
	name: 'setEditSource',
});

export const useEditSource = createHook(Store);
