import { useEffect, useState } from 'react';
import type { ApolloError } from 'apollo-client';

import { isStatusCodeError } from '@confluence/error-types';
import { markErrorAsHandled } from '@confluence/graphql-error-processor';

import { ErrorState } from '../constants/ErrorState';
import type { ClassificationLevel } from '../ClassificationRadioOption';

export const useClassificationErrorState = (
	currentClassification: ClassificationLevel | null,
	spaceLevelError?: ApolloError,
	contentLevelError?: ApolloError,
) => {
	const [errorState, setErrorState] = useState<ErrorState>(ErrorState.NO_ERROR);
	useEffect(() => {
		// If error exists, display corresponding components
		if (!currentClassification) {
			if (spaceLevelError || contentLevelError) {
				if (
					isStatusCodeError(contentLevelError, '404') ||
					isStatusCodeError(spaceLevelError, '404')
				) {
					markErrorAsHandled(contentLevelError);
					markErrorAsHandled(spaceLevelError);
					// If there is a handled error (404), return nothing
					setErrorState(ErrorState.NULL);
				} else {
					// If there is an unhandled error (a non-404 error), return error message with error displays
					setErrorState(ErrorState.MESSAGE);
				}
			}
		} else {
			// If there's a content classification, we don't want to render null nor an error message for a space level error. We'll just mark the error as handled.
			if (spaceLevelError) {
				markErrorAsHandled(spaceLevelError);
			}
			setErrorState(ErrorState.NO_ERROR);
		}
	}, [currentClassification, spaceLevelError, contentLevelError]);
	return errorState;
};
