import gql from 'graphql-tag';

export const ContentOperationsFragment = gql`
	fragment ContentOperationsFragment on Content {
		id
		type
		operations {
			operation
			targetType
		}
	}
`;

export const SpaceOperationsFragment = gql`
	fragment SpaceOperationsFragment on Space {
		id
		operations {
			operation
			targetType
		}
	}
`;
